import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
   

   <path d="M925 2464 c-54 -19 -111 -74 -138 -132 -22 -46 -22 -53 -22 -557 0
-510 0 -510 23 -556 32 -65 84 -113 143 -133 45 -14 115 -16 555 -16 484 0
505 1 558 21 39 14 68 34 96 66 69 79 70 86 70 617 0 302 -4 485 -11 511 -19
72 -68 133 -133 165 l-59 30 -521 -1 c-424 0 -528 -3 -561 -15z m1096 -19 c57
-20 111 -68 141 -125 23 -45 23 -46 26 -524 3 -551 3 -554 -73 -629 -77 -78
-71 -77 -634 -77 -388 1 -504 4 -534 14 -58 21 -105 62 -135 119 l-27 52 -3
463 c-2 293 1 483 8 520 16 90 72 157 156 187 60 22 1011 21 1075 0z"/>
<path d="M950 2401 c-46 -25 -84 -63 -107 -109 -17 -34 -18 -74 -21 -497 -2
-323 0 -472 8 -500 20 -68 56 -114 113 -141 l52 -24 490 0 c554 0 538 -2 608
80 57 67 59 89 55 600 -3 420 -4 458 -21 489 -25 45 -63 80 -112 102 -37 17
-75 19 -535 18 -463 0 -497 -1 -530 -18z m1042 -24 c21 -8 50 -24 64 -38 55
-51 54 -40 54 -558 0 -503 0 -507 -45 -559 -49 -58 -39 -57 -557 -60 -317 -2
-491 1 -517 8 -47 13 -95 50 -119 94 -15 28 -17 74 -15 509 3 448 4 480 22
515 21 41 64 82 85 82 8 0 18 5 21 10 10 16 960 13 1007 -3z"/>
<path d="M1430 2240 c-19 -10 -121 -106 -227 -212 -214 -216 -223 -231 -185
-304 11 -22 105 -123 209 -226 214 -212 238 -227 309 -190 47 25 395 374 419
421 34 68 23 86 -192 303 -234 235 -257 249 -333 208z m297 -223 c180 -180
213 -217 213 -242 0 -25 -32 -62 -209 -239 -196 -196 -212 -210 -246 -210 -34
0 -50 14 -246 210 -177 177 -209 214 -209 239 0 25 33 62 213 242 181 181 217
213 242 213 25 0 61 -32 242 -213z"/>
<path d="M1257 2002 c-168 -169 -197 -202 -197 -227 0 -26 29 -59 198 -228
169 -168 202 -197 227 -197 26 0 59 29 228 198 168 169 197 202 197 227 0 26
-29 59 -198 228 -169 168 -202 197 -227 197 -26 0 -59 -29 -228 -198z m438
-29 c114 -115 175 -184 175 -198 0 -12 -25 -45 -60 -80 l-60 -59 0 43 c0 31
-7 53 -25 76 -24 32 -24 33 -5 50 25 23 26 67 1 89 -28 25 -89 27 -134 4 l-38
-19 -14 35 c-18 43 -57 74 -104 81 -20 4 -53 10 -75 13 l-39 7 58 58 c87 87
104 98 126 86 10 -5 98 -89 194 -186z m-228 -23 c44 -26 61 -82 40 -132 -19
-45 -54 -69 -110 -75 l-43 -5 4 -54 c6 -69 0 -83 -32 -77 l-26 5 0 179 0 179
68 0 c49 0 76 -5 99 -20z m-198 -165 c1 -96 5 -174 11 -185 12 -23 57 -27 84
-7 10 7 20 33 24 61 7 45 10 49 49 65 23 9 52 26 65 38 l23 22 24 -35 24 -35
-21 -15 c-32 -22 -30 -78 3 -100 28 -18 73 -22 120 -10 27 7 24 1 -40 -65
-142 -148 -143 -149 -187 -110 -24 21 -331 327 -357 356 -11 12 149 185 170
185 5 0 8 -74 8 -165z m441 65 c0 -27 -2 -28 -46 -27 -58 2 -68 -16 -23 -40
56 -30 79 -59 79 -97 0 -47 -37 -76 -96 -76 -61 0 -74 8 -74 42 0 26 2 28 23
22 41 -14 81 -10 85 8 2 12 -13 27 -47 49 -47 28 -51 34 -51 70 0 59 43 89
117 81 29 -4 33 -8 33 -32z"/>
<path d="M1360 1855 c0 -63 1 -65 25 -65 31 0 75 41 75 70 0 29 -35 60 -70 60
l-30 0 0 -65z m56 14 c3 -6 -1 -18 -10 -27 -15 -15 -16 -14 -16 11 0 27 13 36
26 16z"/>
<path d="M1924 986 c-33 -14 -44 -33 -44 -70 0 -27 23 -50 64 -63 17 -5 32
-15 34 -21 5 -17 -40 -15 -63 2 -17 12 -21 12 -32 -3 -21 -27 -15 -41 24 -57
71 -30 143 1 143 61 0 37 -12 50 -63 68 -20 6 -35 17 -34 22 2 11 34 12 58 1
11 -5 19 1 27 18 10 22 9 27 -6 35 -34 17 -77 20 -108 7z"/>
<path d="M430 875 l0 -115 33 3 c29 3 32 6 31 34 -2 29 1 31 38 36 54 7 78 31
78 79 0 60 -25 78 -110 78 l-70 0 0 -115z m109 56 c21 -13 5 -41 -24 -41 -18
0 -25 5 -25 18 0 31 21 41 49 23z"/>
<path d="M640 877 l0 -114 30 0 c28 0 30 3 30 39 0 49 20 50 40 2 13 -31 20
-37 53 -40 20 -2 37 -1 37 2 0 2 -11 22 -24 43 -24 37 -24 38 -5 54 26 23 25
81 -1 107 -16 16 -33 20 -90 20 l-70 0 0 -113z m115 38 c0 -14 -8 -21 -27 -23
-24 -3 -28 0 -28 23 0 23 4 26 28 23 19 -2 27 -9 27 -23z"/>
<path d="M860 878 l0 -113 82 2 c81 2 83 2 86 27 3 25 2 26 -52 26 -43 0 -56
3 -56 15 0 11 12 15 50 15 49 0 50 1 50 30 0 29 -1 30 -50 30 -39 0 -50 3 -50
16 0 14 8 15 55 9 l55 -6 0 30 0 31 -85 0 -85 0 0 -112z"/>
<path d="M1060 875 l0 -115 32 3 c32 2 32 3 30 52 l-2 50 25 -30 c14 -16 25
-33 25 -37 0 -21 32 -4 52 27 13 19 26 35 29 35 3 0 4 -22 1 -50 l-5 -50 33 3
33 2 1 113 1 112 -26 0 c-21 0 -34 -12 -64 -56 l-37 -57 -37 56 c-29 46 -41
57 -63 57 l-28 0 0 -115z"/>
<path d="M1360 877 l0 -114 30 0 30 0 0 114 0 113 -30 0 -30 0 0 -113z"/>
<path d="M1460 878 l0 -113 85 0 85 0 0 30 0 30 -55 -3 c-45 -3 -55 -1 -55 12
0 13 11 16 50 16 49 0 50 1 50 30 0 29 -1 30 -51 30 -36 0 -49 4 -46 13 3 8
22 11 56 10 50 -2 51 -2 51 27 l0 30 -85 0 -85 0 0 -112z"/>
<path d="M1667 878 l2 -113 30 -1 c30 -2 31 0 31 37 0 49 14 50 38 3 16 -31
25 -37 55 -40 l37 -3 -24 42 c-24 42 -25 42 -5 66 25 30 24 62 -2 95 -19 24
-27 26 -92 26 l-72 0 2 -112z m113 47 c16 -19 4 -35 -26 -35 -19 0 -24 5 -24
25 0 29 29 35 50 10z"/>
<path d="M2080 906 c0 -69 4 -88 20 -109 37 -47 135 -44 160 4 6 10 10 56 10
103 l0 86 -30 0 -29 0 -3 -76 c-2 -61 -7 -79 -21 -88 -15 -9 -21 -7 -34 9 -11
15 -14 36 -10 88 l6 67 -35 0 -34 0 0 -84z"/>
<path d="M2310 877 l0 -112 78 3 c87 5 112 19 112 63 0 16 -9 37 -21 47 -17
17 -18 20 -5 25 22 9 20 50 -2 70 -14 13 -38 17 -90 17 l-72 0 0 -113z m120
48 c0 -8 -9 -15 -20 -15 -11 0 -23 0 -27 -1 -5 0 -9 7 -11 15 -3 12 5 16 27
16 21 0 31 -5 31 -15z m5 -85 c0 -10 -12 -16 -32 -18 -28 -3 -33 0 -33 18 0
18 5 21 33 18 20 -2 32 -8 32 -18z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
